import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IQuestion, IMCQAnswer, IUser, IELearningData } from '@shared';
import { SubSink } from 'subsink';
import { BackOfficeService } from 'src/app/modules/back-office/back-office.service';
import { FirebaseService } from 'src/app/firebase/firebase.service';

@Component({
  selector: 'app-mcq',
  templateUrl: './mcq.component.html',
  styleUrls: ['./mcq.component.scss']
})
export class McqComponent implements OnInit, OnChanges {
  @Input() moduleId: string;
  @Input() isSelectedCourseLast: boolean = false;
  @Input() isBackOffice: boolean = false;
  @Output() goToNextCourse = new EventEmitter();
  @Input() mcq: IQuestion[];
  @Input() isSubmitted: boolean = false;

  mcqForm: FormGroup;
  isMcqValid: boolean = false;
  errorMessage: string = '';
  showResults: boolean = false;
  currentUser: IUser;

  subs = new SubSink();

  constructor(private fb: FormBuilder, private backOfficeService: BackOfficeService, public firebaseService: FirebaseService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    try {
      this.createMcqForm();
    } catch (e) {}

    this.firebaseService.getCurrentUserProperties().subscribe((user) => {
      this.currentUser = user;
      console.log('user.id=', user);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.mcq && !changes.mcq.firstChange) {
      this.showResults = false;
      this.isSubmitted = false;
      this.resetMcqForm();
      this.createMcqForm();
    }
  }

  resetMcqForm(): void {
    if (this.mcqForm) {
      Object.keys(this.mcqForm.controls).forEach((key) => {
        this.mcqForm.removeControl(key);
      });
    }
  }

  createMcqForm(): void {
    this.isMcqValid = false;
    this.mcqForm = this.fb.group({});

    this.mcq.forEach((question, qIndex) => {
      if (question.questionType !== 'openText') {
        const formArray = this.fb.array(question.propositions.map(() => this.fb.control(false)));
        this.mcqForm.addControl(`Q${qIndex}`, formArray);
      } else {
        this.mcqForm.addControl(`Q${qIndex}`, this.fb.control('', Validators.required));
      }
    });

    this.firebaseService.getELearningData().subscribe((data) => {
      const answers = data?.MCQAnswers?.find((MCQAnswer) => MCQAnswer.moduleId === this.moduleId);
      if (answers) {
        this.prefillFormWithAnswers(answers.userAnswers);
        this.isSubmitted = true;
        this.showResults = true;
        this.disableForm();
      }
    });
  }

  setAnswerValue(questionIndex: number, answerIndex: number): void {
    this.isSubmitted = false;
    const group = this.mcqForm.controls[`Q${questionIndex}`];

    if (typeof group.value === 'string') {
      group.setValue('');
    } else {
      const control = group as FormArray;

      if (this.mcq[questionIndex].questionType === 'singleChoice') {
        control.controls.forEach((control, index) => {
          control.setValue(index === answerIndex);
        });
      } else if (this.mcq[questionIndex].questionType === 'multipleChoice') {
        control.controls.forEach((control, index) => {
          if (index === answerIndex) {
            control.setValue(control.value == 'false' || control.value == false ? true : false);
          }
        });
      }
    }
  }

  prefillFormWithAnswers(userAnswers: any[]): void {
    userAnswers.forEach((userAnswer, qIndex) => {
      const question = this.mcq[qIndex];
      const formControl = this.mcqForm.get(`Q${qIndex}`);

      if (question.questionType === 'openText') {
        formControl.setValue(userAnswer.answers);
      } else if (question.questionType === 'singleChoice') {
        const propositionIndex = question.propositions.findIndex((prop) => prop.proposition === userAnswer.answers);
        if (propositionIndex !== -1) {
          const values = question.propositions.map((_, idx) => idx === propositionIndex);
          formControl.setValue(values);
        }
      } else if (question.questionType === 'multipleChoice') {
        const values = question.propositions.map((prop) => userAnswer.answers.includes(prop.proposition));
        formControl.setValue(values);
      }
    });
  }

  disableForm(): void {
    Object.keys(this.mcqForm.controls).forEach((key) => {
      this.mcqForm.controls[key].disable();
    });
  }

  removeQuestion(index: number): void {
    if (this.isBackOffice) {
      this.mcq.splice(index, 1);
      this.resetMcqForm();
      this.createMcqForm();
    }
  }

  test(): void {
    this.errorMessage = '';
    if (!this.checkRespectMandatory()) {
      this.errorMessage = "Toutes les questions obligatoires n'ont pas été répondues";
    } else {
      this.isSubmitted = true;
      this.showResults = true;
      Object.keys(this.mcqForm.controls).forEach((key) => {
        this.mcqForm.controls[key].disable();
      });

      const MCQanswers: IMCQAnswer = {
        date: new Date(),
        moduleId: this.moduleId,
        userAnswers: []
      };

      let answersArray = Object.keys(this.mcqForm.value)
        .sort((a, b) => parseInt(a.substring(1)) - parseInt(b.substring(1)))
        .map((key) => this.mcqForm.value[key]);

      answersArray.forEach((answer, index) => {
        let answers;
        if (!answers) answers = [];

        if (this.mcq[index].questionType === 'singleChoice' || this.mcq[index].questionType === 'multipleChoice') {
          answer.forEach((proposition, indexP) => {
            if (proposition !== false) {
              if (this.mcq[index].questionType === 'multipleChoice') {
                answers.push(this.mcq[index].propositions[indexP].proposition);
              } else {
                answers = this.mcq[index].propositions[indexP].proposition as string;
              }
            }
          });
        } else if (this.mcq[index].questionType === 'openText') {
          answers = answer;
        }

        MCQanswers.userAnswers.push({
          question: this.mcq[index].question,
          answers
        });
      });

      this.updateELearningMCQAnswers(MCQanswers);
    }
  }

  checkChecked(qIndex: number, pIndex: number): boolean {
    if (this.isSubmitted && !this.isBackOffice) {
      const control = this.mcqForm.get('Q' + qIndex) as FormArray;
      return control.controls[pIndex].value == false || control.controls[pIndex].value == 'false' ? false : true;
    }
    return false;
  }

  checkAnswer(qIndex: number, pIndex: number): string {
    if (this.isSubmitted) {
      const question = this.mcq[qIndex];
      const control = this.mcqForm.get('Q' + qIndex) as FormArray;
      const isCorrect = question.propositions[pIndex].isCorrect;

      if (control.controls[pIndex].value !== false) {
        if (this.isBackOffice) return '';
        return isCorrect ? 'correct' : 'incorrect';
      } else {
        if (this.isBackOffice) return isCorrect ? 'backofficecorrect' : '';
        return isCorrect ? 'missed' : '';
      }
    }
    return '';
  }

  checkRespectMandatory(): boolean {
    let valid = true;
    let answers = this.mcqForm.value;

    let answersArray = Object.keys(answers)
      .sort((a, b) => parseInt(a.substring(1)) - parseInt(b.substring(1)))
      .map((key) => answers[key]);

    for (let index = 0; index < this.mcq.length; index++) {
      const question = this.mcq[index];

      if (question.mandatory) {
        if ((question.questionType === 'singleChoice' || question.questionType === 'multipleChoice') && answersArray[index].every((value) => value === false)) {
          valid = false;
          break;
        } else if (question.questionType === 'openText' && answersArray[index] === '') {
          valid = false;
          break;
        }
      }
    }
    return valid;
  }
  
  updateELearningMCQAnswers(MCQAnswers: IMCQAnswer) {
    this.firebaseService.getELearningData().subscribe((data) => {
      let newELearningData: IELearningData = {
        id: this.currentUser.id,
        completedCourses: [],
        connections: [],
        connectionsV2: [],
        trainingType: 'CPF',
        MCQAnswers: []
      };

      newELearningData = { ...data };
      if (!newELearningData.MCQAnswers) newELearningData.MCQAnswers = [];
      newELearningData.MCQAnswers.push(MCQAnswers);
      this.subs.sink = this.backOfficeService.updateELearningDataForUser(this.currentUser.id, newELearningData).subscribe();
    });
  }
}
