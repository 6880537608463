import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subject, combineLatest, forkJoin, of, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, take, tap, distinctUntilKeyChanged } from 'rxjs/operators';
import { TrainingType, ICampaign, IELearningData, IUser, Status, ITimeInterval, IMasterclass, IChapter, ICourse, IModule, IMasterclassVersion, IQuestion } from '@shared';
import { BackOfficeService } from './back-office.service';
import { FirebaseService } from 'src/app/firebase/firebase.service';
import { SubSink } from 'subsink';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ELearningDataParsingService } from '../e-learning/parsing-service/e-learning-data-parsing.service';
import * as moment from 'moment';
import { NewSubscriberDialogComponent } from './users/new-subscriber-dialog/new-subscriber-dialog.component';
import { BackOfficeELearningService } from './e-learning/back-office-e-learning.service';
import { ELearningElementInfo, IELearningElement, ISelectedELearningElement } from './models/ELearningElement';
import { v4 as uuid } from 'uuid';
import { ConfirmVersionChangeDialogComponent } from './e-learning/confirm-version-change-dialog/confirm-version-change-dialog.component';
import * as _ from 'lodash';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { UserRoleEnum } from '@shared';
import { PromoteUserDialogComponent } from './users/promote-user-dialog/promote-user-dialog.component';
import { EditUserFormDialogComponent } from './users/edit-user-form-dialog/edit-user-form-dialog.component';
import { NewVersionFormDialogComponent } from './e-learning/new-version-form-dialog/new-version-form-dialog.component';

type BackOfficeTab = 'campaigns' | 'users' | 'masterclasses';
@Component({
  selector: 'app-back-office',
  templateUrl: './back-office.component.html',
  styleUrls: ['./back-office.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class BackOfficeComponent implements OnInit {
  campaigns$: Observable<ICampaign[]> = new Observable();
  campaigns: ICampaign[] = [];
  users$: Observable<IUser[]> = new Observable();
  users: IUser[] = [];
  masterclassVersions$: Observable<IMasterclassVersion[]> = new Observable();
  masterclassVersions: IMasterclassVersion[] = [];
  selectedMasterclasses: IMasterclass[] = [];
  userChanged: Subject<IUser> = new Subject<IUser>();

  campaignDataColumns: string[] = ['orderNumber', 'status', 'creationDate', 'TTCBudget', 'email', 'name', 'phoneNumber', 'type', 'platform', 'countries', 'links', 'toSpendBudget', 'commission'];
  userDataColumns: string[] = ['email', 'surname', 'name', 'type', 'trainer', 'report', 'resetPassword', 'reportMCQ', 'edit', 'delete'];
  trainerUserDataColumns: string[] = ['email', 'surname', 'name', 'type', 'edit', 'resetPassword'];
  statusOptions: Status[] = ['cancelled', 'waiting', 'ongoing', 'completed'];
  trainingTypeOptions: TrainingType[] = ['CPF', 'OPCO', 'OPCO70', 'Facture'];
  trainerOptions: IUser[] = [];
  campaignDataSource: MatTableDataSource<ICampaign>;
  userDataSource: MatTableDataSource<IUser>;

  expandedCampaign: ICampaign | null;
  expandedUser: IUser | null;

  isExportV2Enabled = false;

  selectedELearningElement: ISelectedELearningElement;
  selectedMasterclassVersion: IMasterclassVersion; // The one selected for the back-office
  previousSelectedMasterclassVersion: IMasterclassVersion;
  selectedELearningVersion: IMasterclassVersion; // The one selected for the e-learning

  isMasterclassFormDirty = false;

  @ViewChild('campaignPaginator', { static: false }) campaignTablepaginator: MatPaginator;
  @ViewChild('userPaginator', { static: false }) userTablepaginator: MatPaginator;
  @ViewChild('masterclassPaginator', { static: false }) masterclassTablepaginator: MatPaginator;
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if (!sort) {
      return;
    }
    if (!this.campaignDataSource.sort) {
      this.campaignDataSource.sort = sort;
    }
  }

  campaignInputFilter = '';
  userInputFilter = '';
  hideFinishedCampaigns: boolean = false;

  email = '';
  password = '';
  loading = false;
  loadingUsers = true;
  currentUser: IUser | null = null;

  currentTab: BackOfficeTab = 'campaigns';

  masterclassLoading = false;

  selectedRole = '';
  filteredValue = '';

  UserRoleEnum = UserRoleEnum;

  showPassword = false;

  userInput$ = new Subject<KeyboardEvent | string>();

  subs = new SubSink();

  constructor(
    private backOfficeService: BackOfficeService,
    public firebaseService: FirebaseService,
    public dialog: MatDialog,
    private router: Router,
    private eLearningDataParsingService: ELearningDataParsingService,
    public backOfficeELearningService: BackOfficeELearningService,
    private angularFireAuth: AngularFireAuth
  ) {
    this.userChanged.pipe(debounceTime(400), distinctUntilChanged()).subscribe((user) => {
      if (!user) return of();
      this.updateUser(user);
      this.userChanged.next(undefined);
      return of();
    });
  }

  ngOnInit(): void {
    this.campaigns$ = this.backOfficeService.getCampaigns();
    this.users$ = this.backOfficeService.getUsers();
    this.masterclassVersions$ = this.backOfficeService.getMasterclassVersions();
    this.subs.sink = this.users$.subscribe((users: IUser[]) => {
      this.trainerOptions = users.filter((user) => user.role === 'trainer');
    });
    this.userInput$.pipe(debounceTime(500), distinctUntilChanged()).subscribe((event) => {
      if (typeof event === 'string') {
        this.applyUserDataFilter(event);
      } else {
        this.applyUserDataFilter((event.target as HTMLInputElement).value);
      }
    });
  }

  togglePasswordVisibility(event: Event): void {
    event.preventDefault();
    this.showPassword = !this.showPassword;
  }

  applyCampaignDataFilter(event: Event) {
    this.filterFinishedCampaigns(false);
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.campaignInputFilter = filterValue;
    this.campaignDataSource.filter = filterValue.trim().toLowerCase();

    if (this.campaignDataSource.paginator) {
      this.campaignDataSource.paginator.firstPage();
    }
  }

  applyUserDataFilter(value: string) {
    this.filteredValue = value.trim().toLowerCase();
    if (this.filteredValue === 'exportv2') {
      alert('Export version 2 activé !');
      this.isExportV2Enabled = true;
      this.userInputFilter = '';
    } else {
      this.userInputFilter = this.filteredValue;
    }
    this.userDataSource.filter = this.userInputFilter ? this.userInputFilter : JSON.stringify('');

    if (this.userDataSource.paginator) {
      this.userDataSource.paginator.firstPage();
    }
  }

  applyFilter() {
    const filterValue = this.selectedRole ? this.selectedRole.toLowerCase() : this.userInputFilter;

    if (this.userDataSource.filter !== '') {
      this.userDataSource.filter = filterValue + '';
    } else {
      this.userDataSource.filter = JSON.stringify(filterValue);
    }

    if (this.userDataSource.paginator) {
      this.userDataSource.paginator.firstPage();
    }
  }

  filterFinishedCampaigns(hideFinishedCampaigns: boolean) {
    this.hideFinishedCampaigns = hideFinishedCampaigns;
    this.campaignInputFilter = '';
    if (hideFinishedCampaigns) {
      this.campaignDataSource.filter = 'ing'; // waiting, ongoing (this is clearly a hack for MatDataSource filtering)
    } else {
      this.campaignDataSource.filter = '';
    }
  }

  onStatusChange(campaignId: string, newStatus: Status) {
    const newCampaign = this.campaigns.find((campaign) => campaign.id === campaignId);
    if (newCampaign) {
      newCampaign.status = newStatus;
      this.subs.sink = this.backOfficeService.updateCampaign(newCampaign).pipe(take(1)).subscribe();
    } else {
      console.error('An error occured : no campaign found for id ', campaignId);
    }
  }

  onNotesChange(campaignId: string, newNotes: string): void {
    const campaignToUpdate = this.campaigns.find((campaign) => campaign.id === campaignId);
    if (campaignToUpdate) {
      campaignToUpdate.notes = newNotes;
      this.subs.sink = this.backOfficeService
        .updateCampaign(campaignToUpdate)
        .pipe(take(1))
        .subscribe({
          next: () => console.log('Notes successfully updated'),
          error: (err) => console.error('An error occurred while updating the notes: ', err)
        });
    } else {
      console.error('An error occurred: no campaign found for id ', campaignId);
    }
  }

  onTrainerChange(userId: string, newTrainerId: string) {
    const newUser = this.users.find((user) => user.id === userId);
    if (newUser) {
      newUser.trainerId = newTrainerId;
      this.subs.sink = this.backOfficeService.updateUser(newUser).pipe(take(1)).subscribe();
    } else {
      console.error('An error occured : no user found for id ', userId);
    }
  }

  onLearningPeriodChange(user: IUser, newLearningPeriod: ITimeInterval): void {
    let newELearningData: IELearningData = {
      id: user.id,
      learningPeriod: newLearningPeriod,
      completedCourses: [],
      connections: [],
      connectionsV2: [],
      trainingType: 'CPF'
    };
    if (user.eLearningData) {
      user.eLearningData.learningPeriod = newLearningPeriod;
      user.eLearningData.connectionsV2 = [];
      newELearningData = user.eLearningData;
    }
    user.eLearningData = newELearningData;
    this.subs.sink = this.backOfficeService.updateELearningDataForUser(user.id, newELearningData).subscribe();
  }

  onTrainingTypeChange(user: IUser, newTrainingType: TrainingType): void {
    let newELearningData: IELearningData = {
      id: user.id,
      completedCourses: [],
      connections: [],
      connectionsV2: [],
      trainingType: newTrainingType
    };
    if (user.eLearningData) {
      user.eLearningData.trainingType = newTrainingType;
      user.eLearningData.connectionsV2 = [];
      newELearningData = user.eLearningData;
    }
    user.eLearningData = newELearningData;
    this.subs.sink = this.backOfficeService.updateELearningDataForUser(user.id, newELearningData).subscribe();
  }

  openNewSubscriberDialog(trainerId?: string): void {
    this.dialog
      .open(NewSubscriberDialogComponent, {
        width: '400px',
        panelClass: 'new-subscriber-dialog'
      })
      .afterClosed()
      .subscribe((uid: string) => {
        if (!uid) {
          return;
        }
        this.subs.sink = this.backOfficeService.getUserById(uid).subscribe((user: IUser) => {
          user.trainerId = trainerId ?? '';
          this.userDataSource.data.push(user);
          this.loadUsers();
          if (trainerId) {
            this.onTrainerChange(user.id, trainerId);
          }
        });
      });
  }

  openEditUserDialog(user: IUser): void {
    this.dialog
      .open(EditUserFormDialogComponent, {
        width: '700px',
        panelClass: 'user-edit-form-dialog',
        data: {
          currentUser: user,
          masterclasses: this.selectedMasterclassVersion?.masterclasses || []
        }
      })
      .afterClosed()
      .subscribe((mode) => {
        if (mode === 'save') {
          this.loadingUsers = true;
          this.loadUsers();
          this.userInput$.next('');
          this.selectedRole = '';
        }
      });
  }

  translateStatus(status: Status) {
    if (status === 'cancelled') {
      return { value: 'Annulée', color: 'lightgrey' };
    }
    if (status === 'waiting') {
      return { value: 'En attente', color: 'orange' };
    }
    if (status === 'ongoing') {
      return { value: 'En cours', color: 'blue' };
    }
    return { value: 'Terminée', color: 'limegreen' };
  }

  async login() {
    this.loading = true;
    await this.firebaseService
      .signIn(this.email, this.password)
      .then(() => {
        this.access();
      })
      .catch((err) => {
        if (err.code === 'auth/invalid-email') {
          window.alert(`Une erreur est survenue: l'adresse e-mail est invalide`);
        } else if (err.code === 'auth/wrong-password') {
          window.alert(`Une erreur est survenue: le mot de passe est invalide`);
        } else {
          window.alert(`Une erreur est survenue: ${err}`);
        }
      });
    this.email = '';
    this.password = '';
    this.loading = false;
  }

  async access() {
    this.firebaseService.getCurrentUserProperties().subscribe((user) => {
      this.currentUser = user;
      if (this.currentUser) {
        if (this.currentUser?.role === 'admin') {
          this.loadUsers();
          this.loadCampaigns();
          this.loadMasterclasses();
        } else if (this.currentUser?.role === 'trainer') {
          this.loadUsers();
          this.updateTabIndex(1);
          this.loadMasterclasses();
        } else {
          window.alert(`Vous n'avez pas les droits d'accès requis pour accéder au back-office`);
          this.signOut();
        }
      }
    });
  }

  async signInWithGoogle() {
    await this.firebaseService.signInWithGoogle().then(async () => {
      await this.angularFireAuth.setPersistence('local');
      this.access();
    });
    this.email = '';
    this.password = '';
  }

  refreshUsers(): void {
    this.loadingUsers = true;
    this.loadUsers();
    this.selectedRole = '';
  }

  loadUsers(): void {
    this.expandedUser = null;
    this.subs.sink = combineLatest([this.users$, this.backOfficeService.getELearningDataForAll()])
      .pipe(
        switchMap(([users, eLearningDatas]) => {
          eLearningDatas.forEach((data) => {
            if (data.learningPeriod) {
              data.learningPeriod.start = new Date(data.learningPeriod.start);
              data.learningPeriod.end = new Date(data.learningPeriod.end);
            }
          });

          if (this.currentUser.role === UserRoleEnum.TRAINER) {
            users = users.filter((user) => user.trainerId === this.currentUser.id);
          }

          this.users = users.map((user) => ({
            ...user,
            eLearningData: eLearningDatas.find((data) => data.id === user.id)
          }));

          this.userDataSource = new MatTableDataSource(this.users);

          this.userDataSource.filterPredicate = (data: any, filter: string) => {
            const searchText = this.userInputFilter.toLowerCase();
            const isRoleMatch = this.selectedRole ? data.role.toLowerCase() === this.selectedRole.toLowerCase() : true;
            const isTextMatch = Object.keys(data).some((key) => data[key] && data[key].toString().toLowerCase().includes(searchText));

            return isRoleMatch && isTextMatch;
          };

          this.userDataSource.paginator = this.userTablepaginator;
          this.loadingUsers = false;
          return of(users);
        })
      )
      .subscribe();
  }

  loadCampaigns(): void {
    this.subs.sink = this.campaigns$.subscribe((campaigns) => {
      this.campaigns = campaigns;

      this.campaignDataSource = new MatTableDataSource(campaigns);
      this.campaignDataSource.paginator = this.campaignTablepaginator;
    });
  }

  loadMasterclasses(): void {
    this.masterclassLoading = true;
    this.subs.sink = combineLatest([this.masterclassVersions$, this.firebaseService.getRemoteConfig()])
      .pipe(
        switchMap(([versions, remoteConfig]) => {
          this.masterclassVersions = versions;
          this.masterclassVersions = this.masterclassVersions.filter((mv) => !mv.isTemporary);
          const selectedRemoteConfigVersion = versions.find((version) => remoteConfig.parameters['selectedELearningVersion'].defaultValue.value === version.id);
          this.selectedELearningVersion = selectedRemoteConfigVersion;
          this.selectVersion(selectedRemoteConfigVersion);
          this.masterclassLoading = false;
          return of(selectedRemoteConfigVersion);
        })
      )
      .subscribe();
  }

  fakeReloadMasterclasses(versionToSelect?: IMasterclassVersion): void {
    this.masterclassVersions.push(versionToSelect);
    this.masterclassVersions = this.masterclassVersions.filter((mv) => !mv.isTemporary);
    this.masterclassVersions[0].id === this.selectedELearningVersion.id ? this.masterclassVersions.splice(1, 1) : this.masterclassVersions.shift();
    this.selectVersion(versionToSelect);
  }

  selectVersion(version: IMasterclassVersion) {
    this.isMasterclassFormDirty = false;
    this.selectedMasterclassVersion = version;
    this.previousSelectedMasterclassVersion = version;
    this.selectedMasterclasses = version.masterclasses;
    this.selectedELearningElement = null;
  }

  async signOut(): Promise<void> {
    await this.firebaseService.signOut();
    this.router.navigate(['/admin']);
  }

  isUserTheBoss(): boolean {
    return this.currentUser.email === 'mickael@mymusicads.com' || this.currentUser.email === 'dlamarque.pro@gmail.com';
  }

  openPromoteUserDialog(): void {
    this.dialog
      .open(PromoteUserDialogComponent, {
        width: '400px',
        panelClass: 'promote-user-dialog',
        data: {
          currentUser: this.currentUser
        }
      })
      .afterClosed()
      .subscribe((promoteUserData: { email: string; selectedRole: UserRoleEnum }) => {
        if (promoteUserData) {
          const user = this.userDataSource.data.find((user) => user.email === promoteUserData.email);
          user.role = promoteUserData.selectedRole;
          this.updateUser(user);
        }
      });
  }

  getFacebookUrl(user: IUser) {
    if (!user.campaignInfo) return '';
    return user.campaignInfo.facebookUrl ? user.campaignInfo.facebookUrl : 'Non défini';
  }

  getInstagramUrl(user: IUser) {
    if (!user.campaignInfo) return '';
    return user.campaignInfo.instaUrl ? user.campaignInfo.instaUrl : 'Non défini';
  }

  getYouTubeEmail(user: IUser) {
    if (!user.campaignInfo) return '';
    return user.campaignInfo.youtubeEmail ? user.campaignInfo.youtubeEmail : 'Non défini';
  }

  getGoogleAdsId(user: IUser) {
    if (!user.campaignInfo) return '';
    return user.campaignInfo.googleAdsId ? user.campaignInfo.googleAdsId : 'Non défini';
  }

  getBusinessManagerId(user: IUser) {
    if (!user.campaignInfo) return '';
    return user.campaignInfo.businessManagerId ? user.campaignInfo.businessManagerId : 'Non défini';
  }

  private updateUser(user: IUser) {
    this.subs.sink = this.backOfficeService.updateUser(user).pipe(take(1)).subscribe();
  }

  determineUserRoleFr(role: UserRoleEnum) {
    if (role === UserRoleEnum.SUBSCRIBER) {
      return 'abonné';
    }
    if (role === UserRoleEnum.COACH) {
      return 'coach';
    }
    if (role === UserRoleEnum.TRAINER) {
      return 'formateur';
    }
    if (role === UserRoleEnum.ADMIN) {
      return 'admin';
    }
    return 'utilisateur';
  }

  resetUserPassword(user: IUser) {
    this.firebaseService.sendPasswordResetEmail(user.email).then(() => window.alert(`Relance envoyée par mail à l'utilisateur ${user.email}`));
  }

  deleteUser(user: IUser) {
    this.subs.sink = this.backOfficeService.deleteUser(user).subscribe(() => {
      this.userDataSource.data = this.userDataSource.data.filter((oldUser) => user.id !== oldUser.id);
      window.alert(`Utilisateur ${user.email} supprimé !`);
    });
  }

  notifyUserChange(user?: IUser) {
    this.userChanged.next(user);
  }

  updateTabIndex(tabIndex: number) {
    switch (tabIndex) {
      case 0:
        this.currentTab = 'campaigns';
        break;
      case 1:
        this.currentTab = 'users';
        break;
      case 2:
        this.currentTab = 'masterclasses';
        break;
      default:
        this.currentTab = 'campaigns';
        break;
    }
  }

  getTrainerInfoFromUsers(users: IUser[], uid: string): string {
    const trainer = users.find((user) => user.id === uid);
    if (trainer) {
      return trainer.firstName ?? trainer.email;
    }
    return '';
  }

  get isCurrentTabCampaigns() {
    return this.currentTab === 'campaigns';
  }

  get isCurrentTabUsers() {
    return this.currentTab === 'users';
  }

  get isCurrentTabMasterclasses() {
    return this.currentTab === 'masterclasses';
  }

  public exportELearningDataForUser(user: IUser): void {
    this.subs.sink = this.backOfficeService.getELearningDataForUser(user.id).subscribe((data) => {
      let conversionResult = '';
      if (this.isExportV2Enabled) {
        if (!data?.learningPeriod) {
          alert(`Veuillez rentrer une période d'apprentissage pour l'utilisateur ${user.email}.`);
          return;
        }
        if (!data?.trainingType) {
          alert(`Veuillez rentrer un type de formation pour l'utilisateur ${user.email}.`);
          return;
        }
        if (!data.connectionsV2?.length) {
          data.connectionsV2 = this.generateConnectionV2Data(data);
          if (!data.connectionsV2.length) {
            return;
          }
        }
        this.subs.sink = this.backOfficeService.updateELearningDataForUser(data.user.id, data).subscribe((eLearningData) => {
          conversionResult = this.eLearningDataParsingService.exportforUser(eLearningData.connectionsV2, data.user);
          const fileContent = `<body>
            ${conversionResult}
            </body>`;
          this.convertToPdf(fileContent);
        });
      } else {
        conversionResult = this.eLearningDataParsingService.exportforUser(data.connections, data.user);
        if (!conversionResult) {
          window.alert(`L'utilisateur ${user.email} n'a pas d'informations de connexion.`);
          return;
        }

        const fileContent = `<body>
          ${conversionResult}
          </body>`;
        this.convertToPdf(fileContent);
      }
    });
  }

  public exportMCQDataForUser(user: IUser): void {
    this.subs.sink = this.backOfficeService.getELearningDataForUser(user.id).subscribe((data) => {
      if (data) {
        const conversionResult = this.eLearningDataParsingService.exportMCQforUser(data.MCQAnswers, data.user);
        if (conversionResult) {
          const fileContent = `<body>
            ${conversionResult}
            </body>`;
          this.convertToPdf(fileContent);
        } else {
          console.warn(`Aucune donnée à exporter pour l'utilisateur ${user.email}.`);
        }
      } else {
        console.warn(`Aucune donnée trouvée pour l'utilisateur ${user.email}.`);
      }
    });
  }

  public exportELearningDataForAllUsers(): void {
    this.subs.sink = this.backOfficeService.getELearningDataForAll().subscribe((eLearningDatas) => {
      const conversionResults = eLearningDatas.map((data) => this.eLearningDataParsingService.exportforUser(data.connections, data.user)).filter((data) => !!data);
      const result = `
      <body>
      ${conversionResults.join('')}
      </body>
      `;
      this.convertToPdf(result);
    });
  }

  generateConnectionV2Data(data: IELearningData): ITimeInterval[] {
    if (!data.learningPeriod?.start || !data.learningPeriod?.end) {
      return [];
    }

    if (new Date(data.learningPeriod.end).getTime() > new Date().getTime()) {
      alert("La période d'apprentissage se termine après la date du jour. Veuillez la modifier pour utiliser l'export v2.");
      return [];
    }

    const connectionsV2: ITimeInterval[] = [];

    type UserPreference = 'evening' | 'morning' | 'none';
    let userPreference: UserPreference = 'none';
    const random = Math.random();
    userPreference = random < 0.34 ? 'evening' : random < 0.67 ? (userPreference = 'morning') : (userPreference = 'none');

    // Determine the type of contract
    const isOPCO = data.trainingType === 'OPCO';
    const isOPCO70 = data.trainingType === 'OPCO70';
    let totalTargetDuration = isOPCO ? 44 : isOPCO70 ? 60 : 18;
    // Randomize the number
    const sign = Math.random() > 0.5 ? 1 : -1;
    totalTargetDuration += ((sign * totalTargetDuration) / 10) * Math.random();
    // Convert it to minutes
    totalTargetDuration *= 60;

    // First connection between 1h and 2h
    const firstConnectionStart = moment(data.learningPeriod.start).add((15 + Math.random() * 3) * 60, 'minutes');
    const firstConnectionDuration = (1 + Math.random()) * 60;
    const firstConnectionEnd = moment(firstConnectionStart).add(firstConnectionDuration, 'minutes');
    const firstConnection: ITimeInterval = { start: firstConnectionStart.toDate(), end: firstConnectionEnd.toDate() };
    connectionsV2.push(firstConnection);

    // Create connections
    let durationOffset = firstConnectionDuration;
    while (durationOffset < totalTargetDuration) {
      let connectionStart = moment(Date.now());
      const connectionDate = moment(connectionsV2[connectionsV2.length - 1].start)
        .add(1, 'day')
        .startOf('day');
      if (userPreference === 'evening') {
        connectionStart = connectionDate.add(((isOPCO || isOPCO70 ? 14 : 18) + Math.random() * 3) * 60, 'minutes');
      } else if (userPreference === 'morning') {
        connectionStart = connectionDate.add((8 + Math.random() * 3) * 60, 'minutes');
      } else {
        connectionStart = connectionDate.add((9 + Math.random() * 10) * 60, 'minutes');
      }
      const connectionDuration = Math.random() * 3 * 60 * (isOPCO || isOPCO70 ? 2.3 : 1);
      const connectionEnd = moment(connectionStart).add(connectionDuration, 'minutes');
      connectionsV2.push({ start: connectionStart.toDate(), end: connectionEnd.toDate() });
      durationOffset += connectionDuration;
    }

    const allConnectionsDates = this.createDateArray(data.learningPeriod.start, data.learningPeriod.end, connectionsV2.length);
    connectionsV2.forEach((connection, index) => {
      const currentDate = allConnectionsDates[index];
      connection.start = moment(currentDate).add(moment(connection.start).hours(), 'hours').add(moment(connection.start).minutes(), 'minutes').toDate();
      connection.end = moment(currentDate).add(moment(connection.end).hours(), 'hours').add(moment(connection.end).minutes(), 'minutes').toDate();
    });

    return connectionsV2;
  }

  createDateArray(startDate: Date, endDate: Date, wantedDatesNumber: number): Date[] {
    var dateArray = [];
    var currentDate = moment(startDate);
    var stopDate = moment(endDate);
    while (currentDate <= stopDate) {
      dateArray.push(moment(currentDate).toDate());
      currentDate = moment(currentDate).add(1, 'days');
    }
    while (dateArray.length > wantedDatesNumber) {
      const randomIndex = Math.round(Math.random() * (dateArray.length - 1));
      dateArray.splice(randomIndex, 1);
    }
    return dateArray;
  }

  public convertToPdf(html: string): void {
    const iframe = document.getElementById('pdf-iframe') as HTMLIFrameElement;
    const doc = iframe.contentDocument || iframe.contentWindow?.document;

    doc.open();
    doc.write(html);
    doc.close();

    iframe.contentWindow?.focus();
    iframe.contentWindow?.print();
  }

  createNewElement(elementInfo: ELearningElementInfo) {
    if (!this.selectedELearningElement) {
      this.selectedMasterclasses.push(elementInfo as IMasterclass);
    } else {
      if (this.selectedELearningElement.type === 'course') {
        const element = this.backOfficeELearningService.determineElementChildren(this.selectedELearningElement, this.selectedMasterclasses)[0] as ICourse;
        let elementInfosMcq = elementInfo as any;
        element.mcq = [...element.mcq, elementInfosMcq];
      } else {
        this.backOfficeELearningService.determineElementChildren(this.selectedELearningElement, this.selectedMasterclasses).push(elementInfo);
      }
    }
    this.isMasterclassFormDirty = true;
  }

  openNewElement(element: IELearningElement) {
    const parentIds = this.selectedELearningElement?.parentIds ?? [];
    if (this.selectedELearningElement) {
      parentIds.push(this.selectedELearningElement.info.id);
    }
    const newSelectedElement = { ...element, parentIds };
    this.selectedELearningElement = newSelectedElement;
  }

  editElement(element: IELearningElement): void {
    const elementType = element.type;
    if (elementType === 'masterclass') {
      this.selectedMasterclasses = this.selectedMasterclasses.map((masterclass) => (masterclass.id === element.info.id ? (element.info as IMasterclass) : masterclass));
    } else if (elementType === 'module') {
      const targetMasterclass = this.selectedELearningElement.info as IMasterclass;
      targetMasterclass.modules = targetMasterclass.modules.map((module) => (module.id === element.info.id ? (element.info as IModule) : module));
    } else if (elementType === 'chapter') {
      const targetModule = this.selectedELearningElement.info as IModule;
      targetModule.chapters = targetModule.chapters.map((chapter) => (chapter.id === element.info.id ? (element.info as IChapter) : chapter));
    } else if (elementType === 'course') {
      const targetChapter = this.selectedELearningElement.info as IChapter;
      targetChapter.courses = targetChapter.courses.map((course) => (course.id === element.info.id ? (element.info as ICourse) : course));
    } else if (elementType === 'courseContent') {
      const targetCourse = this.selectedELearningElement.info as ICourse;
      targetCourse.mcq = targetCourse.mcq.map((mcq) => (mcq.id === element.info.id ? (element.info as any) : mcq));
    }
    this.isMasterclassFormDirty = true;
  }

  changeElementIndex(initialPosition: number, newPosition: number) {
    this.isMasterclassFormDirty = true;
    const elements = this.backOfficeELearningService.determineElementChildren(this.selectedELearningElement, this.selectedMasterclasses);
    [elements[initialPosition], elements[newPosition]] = [elements[newPosition], elements[initialPosition]];
  }

  deleteElement(element: IELearningElement) {
    const elementType = element.type;
    if (elementType === 'masterclass') {
      this.selectedMasterclasses = this.selectedMasterclasses.filter((masterclass) => masterclass.id !== element.info.id);
    } else if (elementType === 'module') {
      const targetMasterclass = this.selectedELearningElement.info as IMasterclass;
      targetMasterclass.modules = targetMasterclass.modules.filter((module) => module.id !== element.info.id);
    } else if (elementType === 'chapter') {
      const targetModule = this.selectedELearningElement.info as IModule;
      targetModule.chapters = targetModule.chapters.filter((chapter) => chapter.id !== element.info.id);
    } else if (elementType === 'course') {
      const targetChapter = this.selectedELearningElement.info as IChapter;
      targetChapter.courses = targetChapter.courses.filter((course) => course.id !== element.info.id);
    }
    this.isMasterclassFormDirty = true;
  }

  openNewVersionDialog(): void {
    this.dialog
      .open(NewVersionFormDialogComponent, {
        width: '700px',
        panelClass: 'new-version-dialog',
        data: { masterclasses: this.selectedMasterclasses }
      })
      .afterClosed()
      .subscribe((version: IMasterclassVersion) => {
        if (version) {
          this.subs.sink = this.saveVersion$(version).subscribe();
        }
      });
  }

  saveVersion$(version: IMasterclassVersion): Observable<IMasterclassVersion> {
    this.masterclassLoading = true;
    return this.backOfficeService.createMasterclassVersion(version).pipe(
      tap(() => {
        if (!version.isTemporary) {
          this.fakeReloadMasterclasses(version);
          window.alert('Version sauvegardée avec succès!');
        }
        this.masterclassLoading = false;
      }),
      catchError((error) => {
        this.masterclassLoading = false;
        window.alert('Une erreur est survenue. Erreur : ' + error);
        return throwError(() => error);
      })
    );
  }

  onVisualizeClicked(): void {
    if (this.isMasterclassFormDirty) {
      const version: IMasterclassVersion = {
        id: uuid(),
        creationDate: new Date(),
        masterclasses: this.selectedMasterclasses,
        isTemporary: true
      };
      this.subs.sink = this.saveVersion$(version).subscribe(() => {
        this.redirectToVisualization(version.id);
      });
    } else {
      this.redirectToVisualization(this.selectedMasterclassVersion.id);
    }
  }

  redirectToVisualization(versionId: string): void {
    const url = this.router.serializeUrl(this.router.createUrlTree([`/e-learning/visualize/${versionId}/masterclasses`]));

    window.open(url, '_blank');
  }

  onVersionChangeClicked(version: IMasterclassVersion): void {
    setTimeout(() => {
      this.selectedMasterclassVersion = this.previousSelectedMasterclassVersion;
    }, 1);
    if (this.isMasterclassFormDirty) {
      this.openConfirmVersionChange(version);
    } else {
      this.selectVersion(version);
    }
  }

  openConfirmVersionChange(version: IMasterclassVersion): void {
    this.dialog
      .open(ConfirmVersionChangeDialogComponent, {
        width: '700px',
        panelClass: 'confirm-version-change-dialog',
        data: { version }
      })
      .afterClosed()
      .subscribe((save) => {
        if (save) {
          this.openNewVersionDialog();
        } else if (save === false) {
          this.selectVersion(version);
        } else {
          this.selectedMasterclassVersion = this.previousSelectedMasterclassVersion;
        }
      });
  }

  goToPreviousELearningElement(): void {
    let resultElement: ISelectedELearningElement | null = null;
    const targetElementId = this.selectedELearningElement.parentIds[this.selectedELearningElement.parentIds.length - 1];
    const parentIds = this.selectedELearningElement.parentIds?.slice(0, -1);
    if (this.selectedELearningElement.type === 'module') {
      resultElement = { info: this.selectedMasterclasses.find((mc) => mc.id === targetElementId), type: 'masterclass', parentIds };
    } else if (this.selectedELearningElement.type === 'chapter') {
      this.selectedMasterclasses.forEach((mc) => {
        const newElement = mc.modules?.find((module) => module.id === targetElementId);
        if (newElement) {
          resultElement = { info: newElement, type: 'module', parentIds };
        }
      });
    } else if (this.selectedELearningElement.type === 'course') {
      this.selectedMasterclasses.forEach((mc) =>
        mc.modules?.forEach((module) => {
          const newElement = module.chapters.find((chapter) => chapter.id === targetElementId);
          if (newElement) {
            resultElement = { info: newElement, type: 'chapter', parentIds };
          }
        })
      );
    }
    this.selectedELearningElement = resultElement;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
