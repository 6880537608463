<div class="overview">
  <div class="speaker">
    <img class="speaker-image" [src]="masterclass.speaker?.photoUrl ?? '../../../assets/images/masterclasses/question-mark-ssbu.png'" />
    <span class="speaker-name">{{ masterclass.speaker?.name ?? 'Coming soon' }}</span>
  </div>
  <div class="info">
    <span class="name">{{ masterclass.name }}</span>
    <span class="description" [innerHTML]="masterclass.description"></span>
    <div class="progress-and-next-button">
      <div *ngIf="innerWidth > 768" class="progress-bar-wrapper" [ngStyle]="{ visibility: masterclass.isComingSoon ? 'hidden' : 'visible' }">
        <div class="progress-bar-info">
          <span>Progression</span>
          <span [ngStyle]="{ visibility: loadingProgress || masterclass.isComingSoon ? 'hidden' : 'visible' }">{{ masterclass.progress ?? 0 }}%</span>
        </div>
        <div class="progress-bar" [ngClass]="{ loading: loadingProgress }">
          <div class="full" [ngStyle]="{ visibility: loadingProgress ? 'hidden' : 'visible', width: (masterclass.progress ?? 0) + '%' }"></div>
        </div>
      </div>
      <app-button
        *ngIf="masterclass.isComingSoon || masterclass.isObtained || isVisualize; else buyButtons"
        class="next-button"
        text="GO !"
        (click)="goToMasterclassClicked()"
        [disabled]="masterclass.isComingSoon"
      ></app-button>
      <ng-template #buyButtons>
        <div class="buy-buttons">
          <app-button class="next-button" size="sm" [outlined]="true" text="Présentation" (click)="presentationClicked()"></app-button>
          <app-button *ngIf="masterclass.isBuyable" class="next-button" size="sm" text="ACHETER" (click)="buyMasterclassClicked()"></app-button>
          <app-button *ngIf="!masterclass.isBuyable" class="next-button" size="sm" text="OBTENIR" (click)="goToMasterclassClicked()"></app-button>
        </div>
      </ng-template>
    </div>
  </div>
  <img *ngIf="innerWidth < 768" class="close-icon" src="../../../../../assets/icons/close.svg" (click)="closeMasterclassOverviewClicked()" />
</div>
