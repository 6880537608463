<form class="form" [formGroup]="newCourseForm">
  <div class="form-element">
    <label for="name">Nom du cours <span class="mandatory-symbol">*</span></label>
    <input id="name" type="text" formControlName="name" />
  </div>
  <div class="form-element">
    <label for="mediaType">Type de média <span class="mandatory-symbol">*</span></label>
    <div class="checkbox-wrapper">
      <input id="mediaType" type="radio" formControlName="mediaType" value="video" (change)="onMediaTypeChange($event)" /> Vidéo
      <input id="mediaType" type="radio" formControlName="mediaType" value="pdf" /> Fiche
    </div>
  </div>
  <div class="form-element" *ngIf="getMediaType() === 'video'">
    <label for="videoUrl">Url de la vidéo <span class="mandatory-symbol">*</span></label>
    <input id="videoUrl" type="text" formControlName="videoUrl" />
  </div>
  <div class="form-element" *ngIf="getMediaType() === 'pdf'">
    <label for="cheatsheet">Fiche (format pdf) <span class="mandatory-symbol">*</span></label>
    <span class="file-wrapper">
      <input #uploadFileInput id="cheatsheet" type="file" accept="*.pdf" (change)="onCheatsheetChange($event)" />
      <app-button size="sm" text="Télécharger un fichier" [outlined]="true" (onClick)="uploadFileInput.click()"></app-button>
      <span class="file-content-wrapper">
        <ng-container *ngIf="newCourseForm.get('cheatsheet').value; else noFileSelected">
          <img src="../../../../../assets/icons/check.svg" />
          <img class="delete-file" (click)="onCheatsheetChange(null)" src="../../../../../assets/icons/delete.svg" />
        </ng-container>
        <ng-template #noFileSelected>
          <span>Pas de fichier selectionné.</span>
        </ng-template>
      </span>
    </span>
  </div>
</form>
<div class="element-form-buttons">
  <app-button [disabled]="!this.data.elementInfo" theme="danger" [outlined]="true" text="Supprimer" (click)="deleteCourse()"></app-button>
  <app-button [disabled]="!newCourseForm.valid" text="Sauvegarder" (click)="saveCourseWithOperation('save')"></app-button>
  <app-button [disabled]="!newCourseForm.valid" text="Sauvegarder et ouvrir" (click)="saveCourseWithOperation('open')"></app-button>
</div>
<div class="loading-layer" *ngIf="loading">
  <app-loader></app-loader>
</div>
