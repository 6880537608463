import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

export enum AuthRoute {
    SIGNUP = 'signup',
    LOGIN = 'login',
    RESET_PASSWORD = "reset-password"
}

@Component({
    selector: 'app-sign-page',
    templateUrl: './sign-page.component.html',
    styleUrls: ['./sign-page.component.scss']
})
export class SignPageComponent implements OnInit {
    currentRoute: string;
    AuthRoute = AuthRoute;
    redirectUrl: string;

    constructor(private route: ActivatedRoute, private router: Router) { }

    ngOnInit(): void {
        this.route.url.subscribe(url => {
            this.currentRoute = url[0].path;
            console.log("this.currentRoute", this.currentRoute);
        });
        this.route.queryParams.subscribe(params => {
            this.redirectUrl = params['redirectUrl'] || 'home';
        });
    }

    navigate(path: string) {
        this.router.navigate([path]);
    }
}
