<div class="mcq">
  <form class="mcq-form" *ngIf="mcq" [formGroup]="mcqForm" (ngSubmit)="test()">
    <span *ngIf="isBackOffice" class="title-qcm">QCM du module: {{ moduleId }}</span>
    <span *ngIf="!isBackOffice" class="title-qcm-2">Questions sur le cours</span>

    <ng-container *ngFor="let question of mcq; let qIndex = index">
      <div class="question" [ngClass]="{ 'open-text-question': question.questionType === 'openText' }" [formGroupName]="question.questionType !== 'openText' ? 'Q' + qIndex : null">
        <span class="title">
          {{ question.question }}<span *ngIf="question.mandatory">*</span>
          <img class="mobile-menu" mat-icon-button src="../../../../assets/icons/delete.svg" (click)="removeQuestion(qIndex)" />
        </span>
        <div *ngIf="question.questionType === 'openText' && isBackOffice">
          <span class="italic">* texte libre *</span>
        </div>
        <div class="propositions" *ngIf="question.questionType !== 'openText'">
          <div *ngFor="let proposition of question.propositions; let pIndex = index" class="proposition" [ngClass]="checkAnswer(qIndex, pIndex) + '-answer'">
            <input
              class="proposition-input"
              [type]="question.questionType === 'multipleChoice' ? 'checkbox' : 'radio'"
              [formControlName]="pIndex"
              (change)="setAnswerValue(qIndex, pIndex)"
              [disabled]="isSubmitted"
              [name]="'Q' + qIndex"
              [checked]="checkChecked(qIndex, pIndex)"
            />
            <span>{{ proposition.proposition }}</span>
          </div>
        </div>
      </div>
      <div *ngIf="question.questionType === 'openText'">
        <input
          type="text"
          class="open-text-input"
          [formControlName]="'Q' + qIndex"
          [disabled]="true"
          [placeholder]="isSubmitted && !mcqForm.get('Q' + qIndex)?.value ? 'Aucune réponse fournie' : 'Saisissez votre réponse'"
          [ngClass]="{ 'empty-response': isSubmitted && !mcqForm.get('Q' + qIndex)?.value }"
        />
      </div>
    </ng-container>

    <span *ngIf="errorMessage !== ''">{{ errorMessage }}</span>

    <div *ngIf="!isBackOffice" class="buttons">
      <app-button class="confirm-button" text="Confirmer" (click)="test()" *ngIf="!showResults"></app-button>
      <app-button
        class="next-course-button"
        (onClick)="goToNextCourse.emit()"
        [disabled]="isSelectedCourseLast && !isSubmitted"
        [text]="isSelectedCourseLast && !isSubmitted ? 'Compléter le module' : 'Cours suivant'"
        *ngIf="showResults"
      ></app-button>
    </div>
  </form>
</div>
