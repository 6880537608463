import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFireStorage, AngularFireStorageReference } from '@angular/fire/compat/storage';
import { IDocInfo } from '@shared';
import { Utf8AsciiLatin1Encoding } from 'crypto';

@Component({
  selector: 'app-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.scss']
})
export class PdfComponent implements OnInit {
  @Input() pdfInfo: IDocInfo;
  @Input() isSelectedCourseLast: boolean;
  @Input() isBackOffice: boolean = false;
  @Output() goToNextCourse = new EventEmitter();
  @Input() mcq = [];

  constructor(private afStorage: AngularFireStorage) { }

  ngOnInit(): void {
  }

  downloadPdf(): void {
    const storageRef = this.afStorage.refFromURL(this.pdfInfo.url);

    storageRef.getDownloadURL().subscribe((downloadUrl) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = (event) => {
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(xhr.response);
        a.download = this.pdfInfo.name;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        const blob = xhr.response;
      };
      xhr.open('GET', downloadUrl);
      xhr.send();
    });
  }
}
