import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { eLearningNameToId } from '../../utils/e-learning-name-to-id';
import { IQuestion } from '@shared';

@Component({
  selector: 'app-new-question-form-dialog',
  templateUrl: './new-question-form-dialog.component.html',
  styleUrls: ['./new-question-form-dialog.component.scss']
})
export class NewQuestionFormDialogComponent implements OnInit {
  newQuestionForm: FormGroup;
  isSingleChoiceActive = false;
  isMultipleChoiceActive = false;

  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<NewQuestionFormDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: { questionInfo: IQuestion }) {
    this.newQuestionForm = this.fb.group({
      mandatory: [false],
      question: ['', Validators.required],
      questionType: ['singleChoice', Validators.required],
      propositions: this.fb.array([], Validators.required)
    });
  }

  ngOnInit(): void {
    this.onQuestionTypeChange();
    this.mapInfoToForm();
  }

  get propositions(): FormArray {
    return this.newQuestionForm.get('propositions') as FormArray;
  }

  addProposition(): void {
    const propositionGroup = this.fb.group({
      proposition: ['', Validators.required],
      isCorrect: [false]
    });
    this.propositions.push(propositionGroup);
  }

  removeProposition(index: number): void {
    this.propositions.removeAt(index);
  }

  onQuestionTypeChange(): void {
    const questionType = this.newQuestionForm.get('questionType')?.value;
    this.clearPropositions();

    if (questionType === 'singleChoice') {
      this.isSingleChoiceActive = true;
      this.isMultipleChoiceActive = false;
      this.addProposition();
      this.newQuestionForm.get('propositions')?.setValidators([Validators.required, this.singleChoiceValidator]);
    } else if (questionType === 'multipleChoice') {
      this.isSingleChoiceActive = false;
      this.isMultipleChoiceActive = true;
      this.addProposition();
      this.newQuestionForm.get('propositions')?.setValidators([Validators.required, this.multipleChoiceValidator]);
    } else {
      this.isSingleChoiceActive = false;
      this.isMultipleChoiceActive = false;
    }
  }

  singleChoiceValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const propositions = control.value || [];
    const isCorrectCount = propositions.filter((p: any) => p.isCorrect).length;
    return isCorrectCount === 1 ? null : { singleChoiceInvalid: true };
  }

  multipleChoiceValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const propositions = control.value || [];
    const isCorrectCount = propositions.filter((p: any) => p.isCorrect).length;
    return isCorrectCount >= 1 ? null : { multipleChoiceInvalid: true };
  }

  clearPropositions(): void {
    while (this.propositions.length) {
      this.propositions.removeAt(0);
    }
    this.newQuestionForm.get('propositions')?.clearValidators();
    this.newQuestionForm.get('propositions')?.updateValueAndValidity();
  }

  onRadioSelect(selectedIndex: number): void {
    this.propositions.controls.forEach((control, index) => {
      control.get('isCorrect')?.setValue(index === selectedIndex);
    });
  }

  mapInfoToForm(): void {
    const questionInfo = this.data?.questionInfo;
    if (questionInfo) {
      this.newQuestionForm.patchValue({
        mandatory: questionInfo.mandatory,
        question: questionInfo.question,
        questionType: questionInfo.questionType
      });

      if (questionInfo.propositions) {
        questionInfo.propositions.forEach((prop) => {
          const propositionGroup = this.fb.group({
            proposition: [prop.proposition, Validators.required],
            isCorrect: [prop.isCorrect]
          });
          this.propositions.push(propositionGroup);
        });
      }
    }
  }

  onSubmit(): void {
    if (this.newQuestionForm.invalid) {
      alert('Formulaire non valide');
      return;
    }
    const formValue = this.newQuestionForm.value;
    const question: IQuestion = {
      id: eLearningNameToId(formValue.question),
      mandatory: formValue.mandatory,
      question: formValue.question,
      questionType: formValue.questionType,
      propositions: formValue.propositions
    };
    this.dialogRef.close({ element: { info: question, type: 'courseContent' }, operation: 'save' });
  }

  closeDialog(): void {
    this.dialogRef.close({ operation: 'cancel' });
  }
}
