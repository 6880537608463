import * as moment from 'moment';
import { ITimeInterval, IUser, IMCQAnswer } from '../../../../../../shared/src';

export class ELearningDataParsingService {
  exportforUser(connections: ITimeInterval[], user: IUser): string | null {
    if (!connections || !connections.length) {
      return null;
    }

    const [startDate, endDate] = this.getConnectionDatesStrings({ start: connections[0].start, end: connections[connections.length - 1].end });

    const connectionDetailTable = this.formatConnectionDetailTable(connections);
    const totalTime = this.getTotalTime(connections);
    const currentDate = moment().locale('fr').format('DD MMMM YYYY à HH:mm');

    if (totalTime === '00h00m') {
      return null;
    }

    const parsedTemplate = this.exportTemplate
      .replace('{USER_FIRST_NAME}', user.firstName ?? 'Prénom')
      .replace('{USER_LAST_NAME}', user.lastName ?? 'Nom')
      .replace('{USER_EMAIL_ADDRESS}', user.email)
      .replace('{START_DATE}', startDate)
      .replace('{END_DATE}', endDate)
      .replace('{TOTAL_TIME}', totalTime)
      .replace('{TOTAL_TIME}', totalTime)
      .replace('{CURRENT_DATE}', currentDate)
      .replace('{CONNECTION_DETAIL_TABLE}', connectionDetailTable);
    return parsedTemplate;
  }

  exportMCQforUser(MCQAnswers: IMCQAnswer[], user: IUser): string | null {
    if (!MCQAnswers || MCQAnswers.length === 0) {
      alert("Cet utilisateur n'a pas répondu à des QCMs");
      return null;
    }

    let mcqDetailTable = MCQAnswers.map((mcq) => {
      let answersDetail = mcq.userAnswers
        .map((answer) => {
          let answers = Array.isArray(answer.answers) ? answer.answers.join(', ') : answer.answers;
          return `<tr><td>${answer.question}</td><td>${answers}</td></tr>`;
        })
        .join('');

      let formattedDate = moment(mcq.date).locale('fr').format('DD MMMM YYYY à HH:mm');
      return `
        <span><b>Module:</b> ${mcq.moduleId}</b></span><br />
        <span><b>Date:</b> ${formattedDate}</span>
        <table style="width: 100%; border-collapse: collapse;">
          <tr>
            <th style="border: 1px solid #ddd; padding: 8px;">Question</th>
            <th style="border: 1px solid #ddd; padding: 8px;">Réponse(s)</th>
          </tr>
          ${answersDetail}
        </table>
      `;
    }).join('');

    const parsedTemplate = this.templateMCQ
      .replace('{USER_FIRST_NAME}', user.firstName ?? 'Prénom')
      .replace('{USER_LAST_NAME}', user.lastName ?? 'Nom')
      .replace('{USER_EMAIL_ADDRESS}', user.email)
      .replace('{MCQ_DETAIL_TABLE}', mcqDetailTable);

    return parsedTemplate;
  }

  getTotalTime(connections: ITimeInterval[]): string {
    const aggregatedConnections = this.aggregateConnections(connections);
    const totalDuration = aggregatedConnections.reduce((prev, curr) => {
      let [start, end] = this.getConnectionDates(curr) as [moment.Moment, moment.Moment];
      const diff = moment.duration(moment(end).diff(moment(start))).asMilliseconds();
      const roundedToMinuteDiff = diff - ((diff / 1000) % 60) * 1000;
      return prev + roundedToMinuteDiff;
    }, 0);
    const durationMoment = moment.duration(totalDuration);
    return durationMoment.days() * 24 + durationMoment.hours() + 'h' + durationMoment.minutes() + 'm';
  }

  formatConnectionDetailLine(connection: ITimeInterval): string {
    const startString = moment(connection.start).locale('fr').format('DD MMMM YYYY HH:mm');
    const endString = moment(connection.end).locale('fr').format('DD MMMM YYYY HH:mm');

    const [start, end] = this.getConnectionDates(connection) as [moment.Moment, moment.Moment];

    const durationInHours = moment.utc(moment.duration(end.diff(start)).asMilliseconds()).format('HH[h] mm[m]');
    return `
      <tr>
        <td>${startString}</td>
        <td>${endString}</td>
        <td>${durationInHours}</td>
      </tr>
    `;
  }

  formatConnectionDetailTable(connections: ITimeInterval[]): string {
    let connectionDetailTable = '';
    const aggregatedConnections = this.aggregateConnections(connections);
    for (const connection of aggregatedConnections) {
      connectionDetailTable +=
        this.formatConnectionDetailLine(connection) +
        `
      `;
    }
    return connectionDetailTable;
  }

  getConnectionDates(connection: ITimeInterval): [moment.Moment, moment.Moment] | undefined {
    if (!connection) return undefined;
    const start = moment(connection?.start);
    const end = moment(connection?.end);

    return [start, end];
  }

  getConnectionDatesStrings(connection: ITimeInterval): [string, string] | undefined {
    if (!connection) return undefined;
    const start = moment(connection.start).locale('fr').format('DD MMMM YYYY HH:mm');
    const end = moment(connection.end).locale('fr').format('DD MMMM YYYY HH:mm');

    return [start, end];
  }

  getTruncatedConnectionDatesStrings(connection: ITimeInterval): [string, string] | undefined {
    if (!connection) return undefined;
    const start = moment(connection.start).locale('fr').format('DD MMMM YYYY');
    const end = moment(connection.end).locale('fr').format('DD MMMM YYYY');

    return [start, end];
  }

  aggregateConnections(connections: ITimeInterval[]): ITimeInterval[] {
    const aggregatedConnections: ITimeInterval[] = [];
    const connectionMap: Map<string, ITimeInterval[]> = new Map();

    let previousConnection: ITimeInterval | undefined = undefined;
    const sortedConnections = [...connections].sort((a, b) => (a.start as any)._seconds - (b.start as any)._seconds);

    for (let connection of sortedConnections) {
      const [startDateString] = this.getTruncatedConnectionDatesStrings(connection);

      if (!previousConnection) {
        previousConnection = connection;

        connectionMap.set(startDateString, [
          {
            start: connection.start,
            end: connection.end
          }
        ]);
        continue;
      }
      previousConnection = connection;

      if (connectionMap.has(startDateString)) {
        const connectionsForDate = connectionMap.get(startDateString)!;
        const lastEnd = previousConnection.end;
        // if difference between last end and connection.start is under 600 seconds, we consider it's the same connection
        const currentStart = moment(connection.start);
        const previousEnd = moment(lastEnd);
        const isDifferenceSignificant = moment.duration(currentStart.diff(moment(previousEnd))).asSeconds() < 600;

        if (isDifferenceSignificant) {
          connectionsForDate[connectionsForDate.length - 1].end = connection.end;
          connectionMap.set(startDateString, connectionsForDate);
          continue;
        }
      }

      connectionMap.set(startDateString, [
        ...(connectionMap.get(startDateString) || []),
        {
          start: connection.start,
          end: connection.end
        }
      ]);
    }

    for (let connectionsByDay of connectionMap.values()) {
      aggregatedConnections.push(
        ...connectionsByDay.filter((connection) => {
          const [start, end] = this.getConnectionDates(connection) as [moment.Moment, moment.Moment];
          const duration = moment.duration(moment(end).diff(moment(start))).asMinutes();
          return duration >= 10;
        })
      );
    }

    return aggregatedConnections;
  }

  private exportTemplate = `
  <head>
    <style>
      * {
        font-family: Arial, Helvetica, sans-serif;
      }

      #connections {
        border-collapse: collapse;
        width: 100%;
      }

      #connections td, #connections th {
        border: 1px solid #ddd;
        padding: 8px;
      }

      #connections tr:nth-child(even){background-color: #f2f2f2;}

      #connections tr:hover {background-color: #ddd;}

      #connections th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: #04AA6D;
        color: white;
      }
    </style>
  </head>
  <div style="page-break-after: always;">
    <div class="header">
        <b>My Music Ads</b>
        <div>19 rue Claude Tillier</div>
        <div>75012 Paris</div>
        <div>Email: contact@mymusicads.com</div>
        <div>Téléphone: 0633000200</div>
      </div>
    <h1 style="text-align: center;"> Certificat de réalisation </h1>

    <h3><b>Je soussigné(e) Mickael Rainier représentant légal du dispensateur de l’action concourant au développement des compétences My Music Ads atteste que:</b></h3>

    <p>Mme/M. {USER_LAST_NAME} {USER_FIRST_NAME}</p>

    <p>Nature de l’action concourant au développement des compétences :</p>

    <p>Action de formation qui s’est déroulée du {START_DATE} au {END_DATE} pour une durée de {TOTAL_TIME}</p>

    <b>Assiduité du stagiaire</b>
    <p>Durée effectivement suivie par le/la stagiaire : {TOTAL_TIME}</p>

    <b>Suivi détaillé de l'assiduité e-learning</b>
    <p>Relevé de connexion à l'extranet :</p>

    <p>Adresse email utilisée: {USER_EMAIL_ADDRESS}</p>
    <div>
      <table id="connections">
        <tr>
          <th>Date de connexion</th>
          <th>Date de déconnexion</th>
          <th>Durée de connexion</th>
        </tr>
        {CONNECTION_DETAIL_TABLE}
      </table>
    </div>

    <p>Sans préjudice des délais imposés par les règles fiscales, comptables ou commerciales, je m’engage à conserver l’ensemble des pièces justificatives qui ont permis d’établir le présent certificat pendant une durée de 3 ans à compter de la fin de l’année du dernier paiement. En cas de cofinancement des fonds européens  la  durée  de  conservation  est  étendue  conformément  aux  obligations  conventionnelles spécifiques.</p>

    <p>Fait à : Paris</p>
    <p>Le : {CURRENT_DATE}</p>

    <p>Cachet et signature du responsable du dispensateur de formation</p>
    <div>(nom, prénom, qualité du signataire)</div>
  </div>`;

  private templateMCQ = `
  <head>
    <style>
      * {
        font-family: Arial, Helvetica, sans-serif;
      }
      table {
        width: 100%;
        border-collapse: collapse;
        table-layout: fixed;
        word-wrap: break-word;
      }
      th, td {
        border: 1px solid #ddd;
        padding: 8px;
      }
      th {
        background-color: #f2f2f2;
        text-align: left;
      }
      td {
        white-space: normal; /* Permet le retour à la ligne si le contenu est trop long */
      }
    </style>
  </head>
  <div style="page-break-after: always;">
    <div>
      <b>My Music Ads</b>
      <div>19 rue Claude Tillier</div>
      <div>75012 Paris</div>
      <div>Email: contact@nextconversions.fr</div>
      <div>Téléphone: 0633000200</div>
    </div>
    <h1 style="text-align: center;"> Résultat des QCMs </h1>
  
    <b>Informations de l'utilisateur:</b>
  
    <p>Mme/M. {USER_LAST_NAME} {USER_FIRST_NAME}</p>
    <p>Adresse email utilisée: {USER_EMAIL_ADDRESS}</p>
  
    <h3>Relevé des réponses aux QCMs :</h3>
  
  
    <div>
      {MCQ_DETAIL_TABLE}
    </div>
  </div>`;
}
