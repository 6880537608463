<div class="modal-container">
  <form [formGroup]="newQuestionForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label for="mandatory">Obligatoire :</label>
      <input class="checkbox-checkbox" type="checkbox" formControlName="mandatory" id="mandatory" />
    </div>

    <div class="form-group">
      <label for="question">Question :</label>
      <input id="question" type="text" formControlName="question" placeholder="Saisir la question..." />
    </div>

    <div class="form-group">
      <label for="questionType">Type de question :</label>
      <select formControlName="questionType" id="questionType" (change)="onQuestionTypeChange()">
        <option value="singleChoice">Choix unique</option>
        <option value="multipleChoice">Choix multiple</option>
        <option value="openText">Texte libre</option>
      </select>
    </div>

    <div *ngIf="isSingleChoiceActive" class="propositions-container">
      <label>Propositions :</label>
      <div formArrayName="propositions" *ngFor="let proposition of propositions.controls; let i = index">
        <div [formGroupName]="i" class="proposition-group">
          <input type="text" formControlName="proposition" placeholder="Proposition {{ i + 1 }}" />
          <label class="radio-container">
            <input type="radio" [value]="true" [name]="'isCorrect'" (change)="onRadioSelect(i)" [checked]="proposition.get('isCorrect')?.value" />
            Correct
          </label>
          <img class="delete" mat-icon-button src="../../../../../assets/icons/delete.svg" (click)="removeProposition(i)" />
        </div>
      </div>
      <app-button size="sm" [outlined]="true" text="Ajouter une proposition" (onClick)="addProposition()"></app-button>
    </div>

    <div *ngIf="isMultipleChoiceActive" class="propositions-container">
      <label>Propositions :</label>
      <div formArrayName="propositions" *ngFor="let proposition of propositions.controls; let i = index">
        <div [formGroupName]="i" class="proposition-group">
          <input type="text" formControlName="proposition" placeholder="Proposition {{ i + 1 }}" />
          <label class="checkbox-container">
            <input type="checkbox" formControlName="isCorrect" />
            Correct
          </label>
          <button type="button" (click)="removeProposition(i)">Supprimer</button>
        </div>
      </div>
      <button type="button" class="btn-add-proposition" (click)="addProposition()">Ajouter une proposition</button>
    </div>

    <div class="form-actions">
      <app-button size="sm" [outlined]="true" text="Annuler" (onClick)="closeDialog()"></app-button>
      <app-button size="sm" text="Valider" type="submit"></app-button>
    </div>
  </form>
</div>
