<div class="element">
  <div *ngIf="elementType !== 'courseContent'; else courseContent" (click)="openElementFormDialog()">
    <ng-container *ngIf="elementInfo; else newElement">
      <div [class]="elementType + '-card card'" *ngIf="backOfficeELearningService.determineElementDisplay(elementType) === 'card'; else textElement">
        <span *ngIf="elementType === 'masterclass'" class="category">#{{ $any(elementInfo).category | capitalizeFirst }}</span>
        <span *ngIf="elementType === 'module'" class="name">{{ $any(elementInfo).name | capitalizeFirst }}</span>
        <img class="element-img" [src]="elementImageSrc" />
      </div>
      <ng-template #textElement>
        <div class="text-element">
          <span *ngIf="elementType === 'chapter'" class="name">{{ $any(elementInfo).name | capitalizeFirst }}</span>
          <span *ngIf="elementType === 'course'" class="name">{{ $any(elementInfo).name | capitalizeFirst }}</span>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #newElement>
      <div [ngClass]="backOfficeELearningService.determineElementDisplay(elementType) === 'card' ? 'empty-card' : 'empty-text-element'">
        <span class="plus-icon">+</span>
      </div>
    </ng-template>
  </div>
  <ng-template #courseContent>
    <div class="courseContent">
      <app-video *ngIf="getElementInfoAsCourse()?.docInfo.type === 'video'" [videoInfo]="getElementInfoAsCourse().docInfo" [isBackOffice]="true"> </app-video>
      <app-pdf *ngIf="getElementInfoAsCourse()?.docInfo.type === 'pdf'" [pdfInfo]="getElementInfoAsCourse().docInfo" [isBackOffice]="true"> </app-pdf>
      <app-mcq *ngIf="getElementInfoAsCourse()?.mcq?.length" [moduleId]="getElementInfoAsCourse()?.id" [isBackOffice]="true" [isSubmitted]="true" [mcq]="getElementInfoAsCourse()?.mcq" ></app-mcq>
      <app-button (click)="openElementFormDialog()" [outlined]="true" size="md" color="#000" text="Ajouter une question +"></app-button>
    </div>
  </ng-template>
</div>
<div
  *ngIf="elementInfo && elementType !== 'courseContent'"
  [ngClass]="backOfficeELearningService.determineElementDisplay(elementType) === 'card' ? 'change-position-icons-horizontal' : 'change-position-icons-vertical'"
  class="change-position-icons"
>
  <div class="left-arrow-icon" (click)="changePosition(-1)">
    <img src="../../../../../assets/icons/arrow-left-small.svg" />
  </div>
  <div class="right-arrow-icon" (click)="changePosition(1)">
    <img src="../../../../../assets/icons/arrow-right-small.svg" />
  </div>
</div>
