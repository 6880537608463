import { Injectable } from '@angular/core';
import { NewMasterclassFormDialogComponent } from './new-masterclass-form-dialog/new-masterclass-form-dialog.component';
import { NewModuleFormDialogComponent } from './new-module-form-dialog/new-module-form-dialog.component';
import { NewChapterFormDialogComponent } from './new-chapter-form-dialog/new-chapter-form-dialog.component';
import { NewCourseFormDialogComponent } from './new-course-form-dialog/new-course-form-dialog.component';
import { NewQuestionFormDialogComponent } from './new-question-form-dialog/new-question-form-dialog.component';
import { ELearningElementDisplay, ELearningElementInfo, ELearningElementType, IELearningElement } from '../models/ELearningElement';
import { IChapter, ICourse, IMasterclass, IModule } from '@shared';

@Injectable({
  providedIn: 'root'
})
export class BackOfficeELearningService {

  constructor() { }

  determineELearningElementLabel(element: IELearningElement): string {
    if (!element) {
      return '';
    }
    function capitalizeFirstLetter(str: string): string {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    if (element.type === 'masterclass') {
      return `Masterclass : ${capitalizeFirstLetter((element.info as IMasterclass).name)}`;
    } else if (element.type === 'module') {
      return `Module : ${capitalizeFirstLetter((element.info as IModule).name)}`;
    } else if (element.type === 'chapter') {
      return `Chapitre : ${capitalizeFirstLetter((element.info as IChapter).name)}`;
    } else {
      return `Cours : ${capitalizeFirstLetter((element.info as ICourse).name)}`;
    };
  }

  determineELearningElementChildType(elementType: ELearningElementType): ELearningElementType | null {
    if (!elementType) {
      return 'masterclass';
    }
    if (elementType === 'masterclass') {
      return 'module';
    } else if (elementType === 'module') {
      return 'chapter';
    } else if (elementType === 'chapter') {
      return 'course';
    } else if (elementType === 'course') {
      return 'courseContent';
    } else {
      return null;
    };
  }

  determineElementChildren(element: IELearningElement, allMasterclasses: IMasterclass[]): ELearningElementInfo[] {
    if (!element) {
      return allMasterclasses;
    }
    if (element.type === 'masterclass') {
      return (element.info as IMasterclass).modules;
    } else if (element.type === 'module') {
      return (element.info as IModule).chapters;
    } else if (element.type === 'chapter') {
      return (element.info as IChapter).courses;
    } else if (element.type === 'course') {
      return [element.info as ICourse];
    } else {
      return [];
    };
  }

  determineElementDisplay(elementType: ELearningElementType): ELearningElementDisplay {
    if (elementType === 'masterclass' || elementType === 'module') {
      return 'card';
    } else {
      return 'text';
    }
  }

  determineElementFormComponent(elementType: ELearningElementType) {
    if (elementType === 'masterclass') {
      return NewMasterclassFormDialogComponent;
    } else if (elementType === 'module') {
      return NewModuleFormDialogComponent;
    } else if (elementType === 'chapter') {
      return NewChapterFormDialogComponent;
    } else if (elementType === 'courseContent') {
      return NewQuestionFormDialogComponent;
    } 
    else {
      return NewCourseFormDialogComponent;
    };
  }

  determineElementPluralType(elementType: ELearningElementType) {
    if (elementType === 'masterclass') {
      return 'masterclasses/speakers';
    } else {
      return elementType + 's';
    }
  }
}
