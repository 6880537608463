import { Component, ElementRef, OnInit, Renderer2, ViewChild, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { FirebaseService } from 'src/app/firebase/firebase.service';
import { SubSink } from 'subsink';
import { IUser } from '../../../../../../shared/src/interfaces';
import { CallToActionDialogComponent } from '../call-to-action-dialog/call-to-action-dialog.component';

@Component({
  selector: 'app-profile-minified',
  templateUrl: './profile-minified.component.html',
  styleUrls: ['./profile-minified.component.scss']
})
export class ProfileMinifiedComponent implements OnInit {
  innerWidth = window.innerWidth;

  currentUser: IUser | null;

  isOpen = false;
  isPictureLoading = true;
  queryParams;

  @ViewChild('profileMinified') profileMinified: ElementRef | undefined;
  @Input() redirectUrl: string | null = null;
  @Input() mobile: boolean | null = false;

  subs = new SubSink();

  constructor(private firebaseService: FirebaseService, private dialog: MatDialog, private router: Router, private renderer: Renderer2) {
    this.subs.sink = this.firebaseService.getCurrentUserProperties().subscribe((user) => {
      this.isPictureLoading = false;
      this.currentUser = user;
    });

    this.renderer.listen('window', 'click', (e: Event) => {
      if (!this.profileMinified?.nativeElement.contains(e.target)) {
        this.isOpen = false;
      }
    });
  }

  ngOnInit(): void {
    this.queryParams = { queryParams: { redirectUrl: this.redirectUrl } };
    console.log("this.queryParams", this.queryParams);
  }

  openContactUsDemoDialog(): void {
    if (!this.redirectUrl) {
      this.dialog.open(CallToActionDialogComponent, {
        width: '250px',
        panelClass: 'call-to-action-dialog',
        data: {
          title: 'Vous avez aimé la démo ?',
          text: "N'attendez plus et contactez-nous pour demander votre accès et booster votre visibilité !",
          actionText: 'Nous contacter >>',
          actionExtUrl: 'https://mymusicads.com/nous-contacter'
        }
      });
    } else {
      this.router.navigate(['/profile/login'], this.queryParams);
    }
  }

  toggleContextMenu(): void {
    this.isOpen = !this.isOpen;
  }

  goToManageProfile(): void {
    console.log("functioin");
    this.router.navigate(['/profile/manage-profile'], this.queryParams);
  }

  signOut(): void {
    this.firebaseService.signOut();
    if (!this.redirectUrl) {
      this.router.navigate(['/home/demo']);
    }
  }
}
