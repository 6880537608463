import { Component, OnInit, Inject, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FirebaseService } from 'src/app/firebase/firebase.service';
import { FormBuilder, Validators } from '@angular/forms';
import { IELearningData, IMasterclass, IUser } from '@shared';
import { UserRoleEnum } from '@shared';
import { categoriesMapping } from '@shared';
import { v4 as uuid } from 'uuid';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FormArray, FormGroup } from '@angular/forms';
import { CalendlyService } from 'src/app/modules/services/calendly/calendly.service';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { catchError, combineLatest, forkJoin, from, of, tap } from 'rxjs';
import { BackOfficeService } from '../../back-office.service';

@Component({
  selector: 'app-edit-user-form-dialog',
  templateUrl: './edit-user-form-dialog.component.html',
  styleUrls: ['./edit-user-form-dialog.component.scss']
})
export class EditUserFormDialogComponent implements OnInit {
  loading = false;

  isUser: boolean = true;
  isSubscriber: boolean = false;
  isCoach: boolean = false;

  selectedPhotoUrl: SafeUrl | null = null;

  categoriesTitles = Object.keys(categoriesMapping);
  coachingCategoriesOptions = [];

  coachesList: { name: string; schedulingUrl: string; uri: string }[] = [];

  file: File | null = null;

  @ViewChild('masterclassSelect') masterclassSelect: MatSelect;
  allMasterclassSelected = false;

  userForm = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    photoUrl: [''],
    obtainedMasterclasses: [[]],
    facebookUrl: [''],
    instagramUrl: [''],
    youtubeUrl: [''],
    idGoogleAds: [''],
    idGoogleBusinessManager: [''],
    defaultCountries: ['']
  });

  coachForm = this.fb.group({
    coachingCategories: [[], Validators.required],
    coachName: ['', Validators.required],
    description: this.fb.group({
      content: ['', Validators.required],
      bulletsPoints: this.fb.array([]),
      catchphrase: ['']
    }),
    catchphrasecard: ['', Validators.required],
    categoryTitle: ['', Validators.required],
    references: this.fb.array([]),
    sessions: this.fb.array([])
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { currentUser: IUser, masterclasses: IMasterclass[] },
    public dialogRef: MatDialogRef<EditUserFormDialogComponent>,
    private firebaseService: FirebaseService,
    private calendlyService: CalendlyService,
    private backOfficeService: BackOfficeService,
    private fb: FormBuilder,
    private afStorage: AngularFireStorage,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.isCoach = this.data.currentUser.role === UserRoleEnum.COACH;
    this.isSubscriber = this.data.currentUser.role === UserRoleEnum.SUBSCRIBER;

    if (this.data.currentUser) {
      this.userForm.patchValue({
        firstName: this.data?.currentUser?.firstName || '',
        lastName: this.data?.currentUser?.lastName || '',
        obtainedMasterclasses: this.data?.currentUser?.eLearningData?.obtainedMasterclasses || [],
        facebookUrl: this.data?.currentUser?.campaignInfo?.facebookUrl || '',
        instagramUrl: this.data?.currentUser?.campaignInfo?.instaUrl || '',
        youtubeUrl: this.data?.currentUser?.campaignInfo?.youtubeEmail || '',
        idGoogleAds: this.data?.currentUser?.campaignInfo?.googleAdsId || '',
        idGoogleBusinessManager: this.data?.currentUser?.campaignInfo?.businessManagerId || '',
        photoUrl: this.data?.currentUser?.photoUrl || '',
      });
    }

    this.coachingCategoriesOptions = categoriesMapping[this.data?.currentUser?.coachInfo?.categoryTitle] || categoriesMapping['Attaché de presse'];

    if (this.isCoach) {
      this.coachForm.patchValue(this.data.currentUser.coachInfo || {
        calendlyUrl: '',
        description: {
          content: this.data?.currentUser?.coachInfo?.description?.content || '',
          catchphrase: this.data?.currentUser?.coachInfo?.description?.catchphrase || ''
        },
        catchphrasecard: this.data?.currentUser?.coachInfo?.catchphrasecard || '',
        categoryTitle: this.data?.currentUser?.coachInfo?.categoryTitle || '',
      });

      this.coachForm.get('categoryTitle').valueChanges.subscribe(value => {
        this.coachingCategoriesOptions = categoriesMapping[value] || [];
      });

      this.coachForm.get('coachName').valueChanges.subscribe(name => {
        const selectedCoach = this.coachesList.find(coach => coach.name === name);
        if (selectedCoach) {
          this.getCoachSessions(selectedCoach.uri);
        } else {
          this.coachForm.setControl('sessions', this.fb.array([]));
        }
      });

      this.calendlyService.getOrganizationMemberships().subscribe(coachsCalendly => {
        this.coachesList = coachsCalendly;

        const matchingCoach = this.coachesList.find(coach => coach.uri === this.data?.currentUser?.coachInfo?.calendlyUri);

        if (matchingCoach) {
          this.coachesList.sort((a, b) => {
            if (a.name === matchingCoach.name) return -1;
            if (b.name === matchingCoach.name) return 1;
            return 0;
          });

          this.coachForm.patchValue({ coachName: matchingCoach.name });

          this.getCoachSessions(matchingCoach.uri);
        } else {
          this.coachForm.patchValue({ coachName: '' });
        }
      });
    }

    const bulletsPointsData = this.data.currentUser.coachInfo?.description?.bulletsPoints || [];
    bulletsPointsData.forEach((bulletPoint) => {
      this.addBulletPoint(bulletPoint);
    });

    const references = this.data.currentUser.coachInfo?.references || [];
    references.forEach((reference) => {
      this.addReference(reference);
    });
  }

  get sessionsFormArray(): FormArray {
    return this.coachForm.get('sessions') as FormArray;
  }

  get descriptionFormGroup(): FormGroup {
    return this.coachForm.get('description') as FormGroup;
  }

  formatDuration(duration: string): string {
    return this.calendlyService.formatDuration(parseInt(duration));
  }

  getCoachSessions(uri: string) {
    this.calendlyService.getSessions(uri).subscribe(sessions => {
      const existingSessionsWithPrices = this.data.currentUser.coachInfo?.sessions || [];

      const sessionsWithPrices = sessions.map(session => {
        const existingSession = existingSessionsWithPrices.find(es => es.schedulingUrl === session.schedulingUrl);
        return {
          ...session,
          price: existingSession ? existingSession.price : ''
        };
      });

      const sessionsFormGroups = sessionsWithPrices.map(session =>
        this.fb.group({
          schedulingUrl: [session.schedulingUrl],
          duration: [session.duration],
          price: [session.price]
        })
      );

      const sessionFormArray = this.fb.array(sessionsFormGroups);
      this.coachForm.setControl('sessions', sessionFormArray);
    });
  }

  get coachingCategories() {
    return this.coachForm.get('coachingCategories') as FormArray;
  }

  addCoachingCategory(category: string = '') {
    this.coachingCategories.push(this.fb.control(category));
  }

  get references() {
    return this.coachForm.get('references') as FormArray;
  }

  addReference({ title = '', description = '', link = '', photoUrl = '' } = {}) {
    const referenceFormGroup = this.fb.group({
      title: [title, Validators.required],
      description: [description, Validators.required],
      link: [link, Validators.required],
      photoUrl: [photoUrl, Validators.required]
    });
    this.references.push(referenceFormGroup);
  }

  removeReference(index: number) {
    this.references.removeAt(index);
  }

  get bulletsPoints() {
    return this.coachForm.get('description').get('bulletsPoints') as FormArray;
  }

  addBulletPoint(bulletPointContent: string = '') {
    this.bulletsPoints.push(this.fb.control(bulletPointContent));
  }

  removeBulletPoint(index: number) {
    this.bulletsPoints.removeAt(index);
  }

  get photoForDisplay(): SafeUrl | string {
    return this.selectedPhotoUrl ?? this.userForm.get('photoUrl')?.value;
  }

  uploadReferencePicture(event: Event, referenceIndex: number): void {
    const element = event.currentTarget as HTMLInputElement;
    let file = element.files ? element.files[0] : null;

    if (!file) return;

    const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/webp'];
    if (!acceptedImageTypes.includes(file.type)) {
      alert('Format de fichier non supporté.');
      return;
    }

    const filePath = "/referencePictures/" + uuid();
    this.afStorage.upload(filePath, file).then(photo => {
      photo.ref.getDownloadURL().then(photoUrl => {
        (this.references.at(referenceIndex) as FormGroup).patchValue({ photoUrl });
      });
    }).catch(err => {
      alert('Erreur lors du téléchargement de l\'image');
      console.error(err);
    });
  }

  uploadPicture(event: Event): void {
    const element = event.target as HTMLInputElement;
    this.file = element.files ? element.files[0] : null;


    if (!this.file) return;

    const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/webp'];
    if (!acceptedImageTypes.includes(this.file.type)) {
      alert('Format de fichier non supporté.');
      return;
    }

    const blobUrl = URL.createObjectURL(this.file);
    this.selectedPhotoUrl = this.sanitizer.bypassSecurityTrustUrl(blobUrl);
  }

  toggleAllSelection() {
    this.allMasterclassSelected = !this.allMasterclassSelected;  // to control select-unselect

    if (this.allMasterclassSelected) {
      this.masterclassSelect.options.forEach((item: MatOption) => item.select());
    } else {
      this.masterclassSelect.options.forEach((item: MatOption) => { item.deselect() });
    }
    this.masterclassSelect.close();
  }

  async save() {
    this.loading = true;

    if (!this.userForm.valid) {
      alert('Les champs Nom/Prénom doivent être remplis.');
      return;
    }

    if (this.file) {
      try {
        const filePath = "/profilePicture/" + uuid();
        const photo = await this.afStorage.upload(filePath, this.file);
        const photoUrl = await photo.ref.getDownloadURL();
        this.userForm.patchValue({ photoUrl: photoUrl });
      } catch (err) {
        alert('Erreur lors du téléchargement de l\'image');
        console.error(err);
        this.loading = false;
        return;
      }
    }

    let updatedELearningData: IELearningData = {
      ...this.data.currentUser.eLearningData,
      obtainedMasterclasses: this.userForm.value.obtainedMasterclasses
    }

    console.log("this. =>", this.userForm.value.defaultCountries);


    let updatedUser: IUser = {
      ...this.data.currentUser,
      firstName: this.userForm.value.firstName,
      lastName: this.userForm.value.lastName,
      photoUrl: this.userForm.value.photoUrl,
      campaignInfo: {
        facebookUrl: this.userForm.value.facebookUrl,
        instaUrl: this.userForm.value.instagramUrl,
        youtubeEmail: this.userForm.value.youtubeUrl,
        googleAdsId: this.userForm.value.idGoogleAds,
        businessManagerId: this.userForm.value.idGoogleBusinessManager,
        defaultCountries: this.userForm.value.defaultCountries || ''
      }
    };


    if (this.isCoach) {
      if (this.coachForm.valid) {
        let coachInfo = {
          ...this.coachForm.value,
          description: {
            content: this.coachForm.value.description.content,
            bulletsPoints: this.coachForm.value.description.bulletsPoints,
            catchphrase: this.coachForm.value.description.catchphrase
          },
          catchphrasecard: this.coachForm.value.catchphrasecard,
          calendlyUrl: '',
          calendlyUri: ''
        };

        const selectedCoach = this.coachesList.find(coach => coach.name === this.coachForm.value.coachName);

        if (selectedCoach) {
          coachInfo.calendlyUrl = selectedCoach.schedulingUrl;
          coachInfo.calendlyUri = selectedCoach.uri;
        }

        updatedUser.coachInfo = coachInfo;
      }
      else {
        alert('Tous les champs de la partie coaching doivent être remplis.');
        return;
      }
    }

    updatedELearningData?.user && delete updatedELearningData.user;
    updatedUser?.eLearningData?.user && delete updatedUser.eLearningData.user;
    forkJoin([this.backOfficeService.updateELearningDataForUser(updatedUser.id, updatedELearningData), from(this.firebaseService.updateUser(updatedUser))]).pipe(
      tap(() => {
        this.loading = false;
        this.dialogRef.close('save');
      }),
      catchError((err) => {
        alert('Erreur lors de l\'édition de l\'utilisateur. Erreur :' + err);
        this.loading = false;
        return of(err);
      })
    ).subscribe();
  }

  ngOnDestroy() {
    if (this.selectedPhotoUrl) {
      URL.revokeObjectURL(this.selectedPhotoUrl as string);
    }
  }
}
