import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebaseService } from 'src/app/firebase/firebase.service';
import { IConnection, IELearningProgress } from '../models/Progress';
import { SubSink } from 'subsink';
import { IChapter, ICourse, IMasterclassVersion, IModule } from '@shared';
import { MasterclassService } from '../masterclasses/masterclass.service';
import { Observable, combineLatest, forkJoin, switchMap, tap } from 'rxjs';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CourseComponent implements OnInit, OnDestroy {
  masterclassVersion$: Observable<IMasterclassVersion>;
  innerWidth = window.innerWidth;

  module: IModule | null = null;
  chapters: IChapter[] = [];
  selectedCourse = this.chapters[0]?.courses[0];
  isSelectedCourseLast = false;
  connectionInfo: IConnection = { start: new Date(), end: new Date() };

  baseUrl = '';
  loading = true;

  subs = new SubSink();

  constructor(private router: Router, private firebaseService: FirebaseService, private activatedRoute: ActivatedRoute, private masterclassService: MasterclassService) {
    const urlSplit = this.router.url.split('/');

    const masterclassId = urlSplit[urlSplit.indexOf('masterclasses') + 1];
    const moduleId = urlSplit[urlSplit.indexOf('modules') + 1];
    this.baseUrl = this.router.url.split(moduleId)[0];

    if (urlSplit.includes('visualize')) {
      const versionId = urlSplit[urlSplit.indexOf('visualize') + 1];
      this.masterclassVersion$ = this.masterclassService.getMasterclassVersion(versionId);
      this.subs.sink = this.masterclassVersion$.subscribe((masterclassVersion) => {
        this.module = masterclassVersion.masterclasses.find((mc) => mc.id === masterclassId).modules.find((md) => md.id === moduleId);
        console.log("let's load");
        this.loadChapters();
      });
    } else {
      this.subs.sink = combineLatest([this.firebaseService.getRemoteConfig(), this.masterclassService.getObtainedMasterclasses$()])
        .pipe(
          switchMap(([remoteConfig, obtainedMasterclasses]) => {
            const selectedELearningVersion = remoteConfig.parameters['selectedELearningVersion'].defaultValue.value;
            this.masterclassVersion$ = this.masterclassService.getMasterclassVersion(selectedELearningVersion).pipe(
              tap((mcv) => {
                if (!(mcv.id === remoteConfig.parameters['selectedELearningVersion'].defaultValue.value)) {
                  this.router.navigate(['home']);
                }
                this.module = mcv.masterclasses.find((mc) => mc.id === masterclassId).modules.find((module) => module.id === moduleId);
                if (!obtainedMasterclasses.includes(masterclassId) && !this.module.isAvailableInDemo) {
                  this.router.navigate(['home']);
                } else {
                  this.loading = false;
                  this.loadChapters();
                }
              })
            );

            return this.masterclassVersion$;
          })
        )
        .subscribe();
    }
  }

  loadChapters(): void {
  
    this.chapters = this.module?.chapters;
  
    if (!this.chapters) {
      this.loading = false;
      this.router.navigate(['home']);
      return;
    }
  
    document.onvisibilitychange = async () => {
      if (document.visibilityState === 'hidden') {
        this.connectionInfo.end = new Date();
        await this.firebaseService.registerELearningConnection(this.connectionInfo);
      } else {
        this.connectionInfo.start = new Date();
      }
    };
  
    const urlSplit = this.router.url.split('/');
    this.selectCourse(urlSplit[urlSplit.length - 2], urlSplit.pop());
  
    this.firebaseService.getELearningData().subscribe({
      next: (progress: IELearningProgress) => {
        this.chapters.forEach((chapter) => {
          chapter.courses.forEach((course) => {
            course.isCompleted = progress?.completedCourses?.includes(course.id) ?? false;
          });
        });
        this.loading = false; 
      },
      error: () => {
        this.loading = false;
      }
    });
  }
  

  ngOnInit(): void {
    console.log('test');
  }

  selectCourse(selectedChapterId: string, courseId?: string): void {
    let selectedChapter = this.chapters.find((chapter) => chapter.id === selectedChapterId);
    let course = selectedChapter?.courses.find((course) => course.id === courseId);
    if (!selectedChapter) {
      this.router.navigate([`${this.baseUrl}/${this.module.id}/${this.chapters[0].id}/${this.chapters[0].courses[0].id}`]);
      course = this.chapters[0].courses[0];
      selectedChapter = this.chapters[0];
    } else if (!course) {
      this.router.navigate([`${this.baseUrl}/${this.module.id}/${selectedChapter.id}/${selectedChapter.courses[0].id}`]);
      course = selectedChapter.courses[0];
    } else {
      this.router.navigate([`${this.baseUrl}/${this.module.id}/${selectedChapter.id}/${course.id}`]);
    }
    this.selectedCourse = course;
    this.isSelectedCourseLast = this.isLastCourse(selectedChapter, this.selectedCourse);
  }

  chapterCompletion(chapter: IChapter) {
    const completedCourses = chapter.courses.filter((course) => course.isCompleted).length;
    return Math.round((completedCourses / chapter.courses.length) * 100);
  }

  goToNextCourse() {
    this.firebaseService
      .registerELearningProgress(this.selectedCourse.id)
      .then(() => {
        let selectedChapter: IChapter;
        this.chapters.forEach((chapter) =>
          chapter.courses.forEach((course) => {
            if (course.id === this.selectedCourse.id) {
              course.isCompleted = true;
            }
            course.id === this.selectedCourse.id ? (selectedChapter = chapter) : null;
          })
        );
        if (this.isSelectedCourseLast) {
          this.router.navigate([`${this.baseUrl}`]);
          return;
        }
        const courseIndex = selectedChapter.courses.indexOf(this.selectedCourse);
        if (courseIndex < selectedChapter.courses.length - 1) {
          const newCourse = selectedChapter.courses[courseIndex + 1];
          this.selectCourse(selectedChapter.id, newCourse.id);
        } else {
          const selectedChapterIndex = this.chapters.indexOf(selectedChapter);
          const newChapter = this.chapters[selectedChapterIndex + 1];
          this.selectCourse(newChapter.id);
        }
      })
      .catch((err) => console.error(err));
  }

  isLastCourse(selectedChapter: IChapter, selectedCourse: ICourse) {
    return (
      this.chapters.findIndex((chapter) => chapter.id === selectedChapter?.id) === this.chapters.length - 1 &&
      selectedChapter?.courses.findIndex((course) => course.id === selectedCourse.id) === selectedChapter?.courses.length - 1
    );
  }

  navigateToModules(): void {
    this.router.navigate([this.baseUrl]);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
